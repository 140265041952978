<template>
  <div class="main-content">
    <div class="main-title">编辑合作伙伴</div>
    <div class="from">
      <div class="row">
        <div class="title">合作伙伴名称</div>
        <input type="text" placeholder="请输入合作伙伴名称" v-model.trim="linkObj.title">
      </div>
      <div class="row">
        <div class="title">链接地址</div>
        <input type="text" placeholder="请输入链接地址" v-model.trim="linkObj.link_url">
      </div>

      <div class="thumb-box">
        <div class="title">图标</div>
        <label for="def_input">
          <img :src="linkObj.thumb_img || require('@/assets/upload_black_icon.png')" alt=" ">
        </label>
        <input type="file" accept="image/*" hidden id="def_input" @change="getFile">
      </div>
      <div class="btn-box">
        <span class="btn-confirm" @click="edit">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getLinkInfo,editLink} from "@/request/api";
import {Warning} from "@/plugins/warning";
import { LoadingFn } from '@/plugins/loading';
import $ from "jquery";
import OSS from "ali-oss";

export default {
  name: "link_friend_edit",
  components:{

  },
  data() {
    return {
      id:0,
      linkObj:{},
      thumb:"",
    }
  },
  computed: {

  },
  created() {
    this.id = this.$route.query.id;
    if(this.id > 0){
      this.getInfo();
    }
  },
  mounted() {

  },
  methods: {
    edit(){
      if(this.id == undefined){
        this.id = 0;
      }
      const requestData = {
        "id":this.id,
        "type":2,
        "title":this.linkObj.title,
        "thumb_img":this.thumb,
        "link_url":this.linkObj.link_url,
      };

      editLink(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
    getInfo(){
      const requestData = {"id":this.id};
      getLinkInfo(requestData).then((res)=>{
        this.linkObj = res.data;
      })
    },
    getFile(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"link"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.thumb = data.result.data.url
                    that.articleObj.thumb_img = data.result.data.full_url
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      & > textarea{
        height: 200px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding: 15px;
      }
      .el-select{
        margin-right: 15px;
      }
      .address{
        margin-top: 15px;
        min-width: 600px;
      }
    }
    .thumb-box{
      width: 200px;
      height: 200px;
      position: absolute;
      top:0;
      right: 30px;
      label {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          margin-top: 15px;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>
